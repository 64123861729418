.ui-widget {
  font-family: inherit;
}

.ui-accordion .ui-accordion-icons {
  padding-left: 0;
  border: solid silver;
  border-width: 1px 0 0;
  border-radius: 0 !important;
  background: transparent;

  @include add-drop-down($bright-red);

  &:after {
    margin-top: -30px;
  }
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  display: none;
}

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited, .ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
  @include condensed-heading($bright-red);
  font-size: 1.1em;
}

.ui-accordion .ui-accordion-content {
  border-top: 0;
  overflow: auto;
  border: none;
  padding: 0;
}