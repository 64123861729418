.page-video-gallery h1#page-title {
  display: none;
}

.view-id-video_gallery {

  h2 {
    font-size: 1.9em;
  }

  .views-row {
    margin: 0 $gutter / 2;
  }

  //Attachment
  > .attachment {
    iframe.media-youtube-player {
      width: 270px;
      height: 150px;
    }

    .view-content > .views-row-first {
      .field-name-field-video-category {
        display: none;
      }
    }

    .view-content {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      justify-content: center;

      > .views-row {
        &:first-child {
          flex: 1 1 48%;

          iframe.media-youtube-player {
            width: 100%;
            height: 309px;
          }
        }

        flex: 0 0 auto;
      }
    }
  }

  //Main Display
  .view-content {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;

    > .views-row {
      flex: 0 0 auto;
      margin-bottom: 2em;
    }
  }

  .view-filters {
    float: right;
    margin-top: -0.5em;
  }

  .views-exposed-widgets {
    margin: 1em auto;
  }

  .views-exposed-widget {
    float: right;
    padding: 0;

    label {
      display: inline;
      margin-right: 0.5em;
    }

    .views-widget {
      float: right;
      display: inline-block;
      height: 1.3em;
    }
  }

  //Pager
  > .item-list {

  }

}