@import "../../../neato/bower_components/neat/app/assets/stylesheets/neat-helpers";
//Neat Overrides
$column: 70px;
$gutter: 30px;
$grid-columns: 12;
$max-width: em(1280);

// Neat Breakpoints
$small-screen: em(320);
$medium-screen: em(640);
$large-screen: em(860);
$full-screen: $max-width;

$small-screen-down: new-breakpoint(max-width $small-screen, 1);
$small-screen-up: new-breakpoint(min-width $small-screen 1);
$medium-screen-up: new-breakpoint(min-width $medium-screen 6);
$large-screen-up: new-breakpoint(min-width $large-screen 12);
$full-screen-up: new-breakpoint(min-width $full-screen, 12);

// Visual Grid
$visual-grid: false !global;
$visual-grid-color: red !global;