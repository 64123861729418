@mixin escape-main {
  margin: 0 -30px;
  width: calc(100% + 60px);
}

body.page-contact-us main {
  padding-top: 0;


  &:before {
    background-image: url("/sites/all/themes/aucor/images/contact-us-top.png");
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    content: "";
    display: block;
    max-height: 325px;
    height: calc(100vw * 325/1280);
    @include escape-main;
  }

  h1#page-title {
    text-align: center;
    @include escape-main;
    background-color: $light;
    padding: .5em 0;
  }

  section#content {
    @include escape-main;
  }
}


.view-contact-us {
  padding-bottom: 1.5em;

  .attachment {
    display: inline-block;
    width: 100%;
    background-color: $light;
    padding: 0;
    margin-bottom: 3em;

    .view-content {
      width: 100%;
      padding: 0;
      overflow: hidden;

      @include media($medium-screen-up) {
        width: 50%;
      }

      @include media($large-screen-up) {
        width: 30%;
      }
    }

    .view-header {
      display: inline-block;
      float: left;
      width: 100%;

      .hq-image {
        display: none;
        float: left;
      }

      .national {
        a {
          color: $bright-red;
        }

        padding-top: .5em;
        color: $bright-red;
        font-weight: bold;
        font-size: 1.1em;
        padding-left: 30px;
        float: left;
        width: 50%;
      }

      @include media($medium-screen-up) {
        width: 50%;
      }

      @include media($large-screen-up) {
        width: 70%;

        .hq-image {
          display: block;
          width: 60%;
        }

        .national {
          width: 40%;
        }
      }

      @include media($full-screen-up) {
        width: 70%;
        .hq-image {
          transform: translateX(0);
          width: 60%;
        }
        .national {
          width: 40%;
        }
      }
    }

    .branch-image {
      display: none;
    }

    .node-aucor-branch.node-teaser {
      height: 223px;
      margin-bottom: 0;

      .branch-details {
        background-color: transparent;
        padding-left: $gutter / 2;
        box-shadow: none;

        &> * {
          text-align: left;
        }
      }
    }

    .branch-banking-details h2 {
      margin: 0 auto $gutter !important;
    }

    .views-row .field-name-empty-divider {
      display: inline-block;
      content: "Banking Details";
      bottom: 0em;
      margin: auto;
      text-align: center;
      position: absolute;
      left: 20px;
      transform: none;
      cursor: pointer;
      background-color: darken($light,25%);
      padding: 0.15em .5em 0em;
      transition: 850ms ease all;
    }
  }

  span.label-above {
    float: left;
    width: 100%;
    color: black;
  }

  .view-content{
    display: inline-flex;
    flex-flow: row wrap;
    align-content: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 0 $gutter;
  }

  .views-row {
    flex: 0 0 auto;

    .field-name-empty-divider {
      display: inline-block;
      content: "Banking Details";
      bottom: 1em;
      margin: auto;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      background-color: $light;
      padding: 0.15em .5em 0em;
      transition: 850ms ease all;

      &:after {
        content: "Banking Details";
        color: $dark-gray;
      }

      &.toggle {
        left: 90%;
        bottom: 88%;
        @include close-button($medium-gray);
      }

      &:hover {
        padding: 0.15em .5em 0em;
        background-color: $medium-gray;
        &:after {
          color: white;
        }
      }
    }
  }

}

.node-aucor-branch.node-teaser {
  position: relative;
  height: 425px;
  width: 250px;
  margin-bottom: $gutter;

  h2 {
    font-size: 1.1em;
    margin-top: .5em;
  }

  .branch-details, .branch-banking-details{
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: center;
    transform-style: preserve-3d;
    perspective: 2000px;
    perspective-origin: center;
    backface-visibility: hidden;
    transition: 1.4s ease-in all;
    width: 100%;
    height: 100%;

  }

  .branch-details {
    transform: rotateY( 0deg);
    background: white;
    opacity: 1;
    box-shadow: 0px 0px 8px -3px rgba(0,0,0,0.26);

    .views-row-odd & {
      //background: #fcfcfc;
    }

    &:after {

    }

    &.toggle {
      transform: rotateY(180deg);
      opacity: 0;
    }
  }

  .branch-banking-details {
    transform: rotateY(180deg);
    opacity: 0;
    background: $light;
    box-shadow: 0em 0em 92px -36px rgba(0, 0, 0, 0.26);
    &.toggle {
      transform: rotateY(0deg);
      opacity: 1;
    }

    h2 {
      color: $bright-red;
      margin: 3em auto;

      &:after {
        content: "Banking Details";
        float: left;
        width: 100%;
      }
    }

    .field {
      color: $medium-gray;
    }

    .label-inline {
      color: $bright-red;
    }
  }


  .branch-details > div:not(.branch-image),
  .branch-banking-details > * {
    padding: 0 $gutter / 2;
    text-align: center;
    font-weight: bold;
  }

  .field-name-field-physical-address {
    color: $medium-gray;
    margin-bottom: $gutter / 2;
  }

  .label-inline {
    color: $bright-red;
    float: none;
  }

  .field-name-field-bank {
    font-size: 1.1em;
    color: $dark-gray;
  }
}