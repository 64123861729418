body {
  &:after {
    position: fixed;
    right: 0;
    bottom: 0;
    border: solid silver;
    border-width: 1px 0 0 1px;
    font-size: 0.8em;
    font-weight: 300;
    padding: 0.5em;

    @include media($small-screen-down) {
      content: "small-screen-down";
      background-color: hsla(255, 55%, 54%, 0.8);
    }

    @include media($small-screen-up) {
      content: "small-screen-up";
      background-color: hsla(200, 55%, 54%, 0.8);
    }

    @include media($medium-screen-up) {
      content: "medium-screen-up";
      background-color: hsla(155, 55%, 54%, 0.8);
    }

    @include media($large-screen-up) {
      content: "large-screen-up";
      background-color: hsla(30, 75%, 74%, 0.8);
    }

    @include media($full-screen-up) {
      content: "full-screen-up";
      background-color: hsla(0, 95%, 74%, 0.8);
    }
  }
}

// Regions

#site-header, #sidebar-first, #sidebar-second, #sidebar-left, #sidebar-right, #content  {
  outline: 0px dashed maroon;
  outline-offset: 2px;
  position: relative;

  &:before {
    position: absolute;
    left: 10px;
    top: -8px;
    //content: attr(id);
    font-family: monospace;
    transform-origin: left;
    transform: rotate(-270deg);
    background-color: black;
    color: yellow;
    padding: 0 4px;
    z-index: 1;
  }
}

div#server-info {
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  padding: 0.5em 1em;
  border: 1px solid brown;
  opacity: 0.5;
}

.page {
  background: rgb(245, 245, 245);
}