.front {
  main {
    padding-left: 0;
    padding-right: 0;
  }

  .slick-slide img {
    display: block;
    margin: auto;
    height: calc(.35 * 100vw);
    max-height: 448px;
  }
}

.front .node-page {
  background-color: $light;
  text-align: center;
  padding: $gutter $gutter 0 $gutter;
  font-weight: bold;
  letter-spacing: 1px;

  h2 {
    font-size: 2em;

    a {
      color: black;
    }
  }

  .links.inline {
    display: block;
    height: 35px;
    width: 100%;
  }

  .node-readmore {
    text-align: right;
    float: right;
    @include aucor-button(white);
    @include black-gradient;
  }
}

.front .media_embed {
  max-width: 100%;
  height: calc(100vw * .5625);
  max-height: 720px;
  width: 100%;

  iframe {
    max-width: 100%;
    height: calc(100vw * .5625);
    max-height: 720px;
    width: 100%;
  }
}

ul.slick-dots {
  position: absolute;
  bottom: 2em;
  right: 2em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  li {
    flex: 0 0 2em;
    margin-left: .5em;

    button {
      background-color: rgba(255,255,255,0.5);
      color: white;
      border-radius: 0;
      padding: 0.5em .75em 0.4em;
      mix-blend-mode: hard-light;
    }

    &.slick-active {
      button {
        background-color: $bright-red;
      }
    }
  }
}

.red-dots-block {
  padding-bottom: 1em;
}

.calendar-home {
  margin: 1em 0 0;
  h2 {
    text-align: center;
    @include condensed-heading($bright-red);
  }
}