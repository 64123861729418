.entitytype-auction_enquire-form, .entitytype-notify_me-form {
 width: 530px;
 margin: 10px;
}

#content #notify-me {
  display: none;
}

div#edit-field-subscribe-to {
  float: left;
  width: 100%;
}

.form-item-field-subscribe-to-und {
  float: left;
  width: 100%;
}

#cboxWrapper {
  border-radius: 0;
  @include background_red_gradient;

  label {
    color: white;
    span {
      color: white !important;
    }
  }
}

div#colorbox {
  outline: 0;
  box-shadow: 0px 0px 40px 5px black;
}

#cboxContent {
  background: transparent;
  overflow: hidden;
  padding-bottom: .5em;

  button#cboxClose {
    top: 0;
    background: transparent;
    text-indent: 0;
    font-size: 0;
    color: transparent;

    @include close-button;
  }

  #edit-field-subscribe-to-und {
    label {
      padding: 0 1em 0 0;
      width: auto;
    }

    input {
      width: auto;
      float: left;
      margin-top: 2px;
    }
  }
}

#cboxLoadedContent {
  overflow: hidden !important;
  position: relative;
  margin: 0;

  &:after {
    content: "";
    background-image: url(/sites/all/themes/aucor/images/logo.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100px;
    height: 46px;
    border: 5px solid white;
    background-color: white;
    border-radius: 2px;
  }

  .field-type-text, .field-type-email {
    float: left;
    width: 50%;

    .form-item label {
      width: 22%;
    }
  }

  #edit-field-enq-auction {
    width: 100%;
  }

  .field-name-field-tel-no, .field-name-field-surname {
    .form-item label {
      width: 25%;
    }
  }

  .grippie {
    display: none;
  }

  h2 {
    font-size: 1.7em;
    color: white;
    margin: 0 0 0.5em;
  }

  .form-actions {
    float: right;

    input{
      @include aucor-button();
      @include black-gradient;
      padding: 1em;
    }
  }

  .form-item {

    margin: 0;
    label {
      float: left;
      width: 17%;
    }

    input {
      width: 75%;
    }

    div, input[disabled="disabled"] {
      float: left;
      width: 89%;
    }
  }
}