$base-spacing: 1.5em !default;
$alert-color: #fff6bf !default;
$error-color: #fbe3e4 !default;
$notice-color: #e5edf8 !default;
$success-color: #e6efc2 !default;
$message-border-radius: 5px;

@mixin flash($color) {
  background-color: $color;
  color: darken($color, 60%);
  display: block;
  margin-bottom: $base-spacing / 2;
  padding: $base-spacing / 2 $base-spacing / 2 $base-spacing / 2 50px;
  border-radius: $message-border-radius;

  a {
    color: darken($color, 70%);
    text-decoration: underline;

    &:focus,
    &:hover {
      color: darken($color, 90%);
    }
  }
}

#messages .outer-wrapper {
  position: relative;
}

@keyframes messages-go {
  0% {
    transform: rotate(0deg);
    width: 100%;
    filter: hue-rotate(0deg) drop-shadow(0 0 3px black);
  }

  50% {
    opacity: 1;
    width: 65%;
  }

  100% {
    transform: rotateX(-360deg);
    opacity: 0;
    width: 10%;
    filter: hue-rotate(600deg) drop-shadow(0 0 13px black);
  }
}

.messages {

  position: absolute;
  transform-origin: right;
  transform-style: preserve-3d;
  animation-name: messages-go;
  animation-duration: 5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 5s;
  overflow-x: hidden;
  width: 100%;
  margin: 1em;
  z-index: 1;

  &.error {
    @include flash($error-color);
  }

  &.status {
    @include flash($success-color);
  }

  &.notice {
    @include flash($notice-color);
  }

  &.warning {
    @include flash($alert-color);
  }
}

.block-workbench {
  padding: 1em 2em 0;
}