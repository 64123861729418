.auction_calculator {
  margin: auto;

  h2 {
    text-align: center;
    @include condensed-heading(black);
  }

  label {
    color: $medium-gray;
    white-space: nowrap;
  }

  form > div > p {
    text-align: center;
    color: $medium-gray;
    max-width: 25em;
    margin: auto;
  }

  fieldset {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }

  .fieldset-wrapper {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    align-items: flex-end;

    &.top-row {
      justify-content: center;
    }

    &.bottom-row {
      justify-content: center;
    }

    > div {
      flex: 1 1 30%;
      position: relative;
      margin: 0 5px 1em 0;

      &:last-child {
        margin: 0 0 1em 0;
      }

      span.field-suffix {
        position: absolute;
        right: 0.5em;
        bottom: 0%;
        line-height: 2.2;
        color: $bright-red;
      }

      &.form-item-commission-percentage {
        flex: 0 1 32.5%;
      }

      &.form-item-other-fees {
        flex: 0 1 30%
      }

      &.form-item-vat-percentage {
        flex: 0 2 30%;
      }
    }

    input {
      margin: 0;
      border-color: $bright-red;
    }
  }

  #bid_costs_calculation {
    color: $bright-red;
    text-align: center;
    font-weight: bold;
    line-height: 1.1;
    flex: 2 0 30%;
    white-space: nowrap;
  }

  .calculator-submit {
    text-align: center;
    input {
      @include background_red_gradient();
      width: 160px;
    }
  }

  legend {
    @include condensed-heading($medium-gray);
    margin: 0 auto;
  }
}