header#site-header {
  background: white;
  $header-height: 140px; //Overall
  $search-block-width: 220px;


  .outer-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;

    > div {
      margin: $gutter / 2 auto;

      @include media($medium-screen-up) {
        margin: $gutter / 2 0;
      }

      &:first-of-type {
        padding-left: $gutter;
      }
    }

    > section {
      flex: 1 0 auto;
      align-self: flex-end;
      line-height: 100%;

      &.block-system {
        display: none;
      }

      @include media($medium-screen-up) {
        &.block-menu {
          display: inherit;
        }
      }

      &:last-child {
        padding-right: $gutter / 3;
      }

      @include media($large-screen-up) {
        &:last-child {
          padding-right: $gutter;
        }
      }

      &.menu-super-menu {
        align-self: center;
        flex: 1 1 auto;
        margin: $gutter / 2 0;

        li {
          padding: 0 !important;
        }
        a {
          white-space: nowrap;
        }
      }

      $main-menu-bar-height: 65px;

      @mixin main-menu-style {
        @include background_red_gradient;
        height: $main-menu-bar-height;
      }

      &.main-menu {
        flex: 1 1 calc(100% - 100px);

        @include media($medium-screen-up) {
          flex: 1 1 calc(100% - 140px);
        }

        @include media($large-screen-up) {
          flex: 1 1 calc(100% - 220px);
        }
        align-self: flex-start;
        @include main-menu-style;

        > ul > li.expanded {
          &:before {
            position: absolute;
            content: "";
            display: block;
            width: 100%;
            height: 125%;
            top: 86%;
            background-color: transparent;
          }
        }


        ul.menu   a {
          color: white;
        }
      }

      &.-exp-auctions-page { // This is the search block inline with the .main-menu above.

        flex: 1 0 100%;

        @include media($medium-screen-up) {
          flex: 1 1 calc(140px);
        }

        @include media($large-screen-up) {
          flex: 1 1 calc(220px);
        }

        align-self: flex-start;
        @include main-menu-style;
        padding-top: (($main-menu-bar-height - 37px) / 2);
        border-left: 1px solid white;
        border-right: 1px solid white;
        padding-left: 10px;

        @include media($large-screen-up) {
          padding-left: 30px;
        }


        .views-exposed-widgets {
          position: relative;
          margin: 0;
        }

        #edit-search-wrapper {
          padding: 0;
          float: none;
          input {
            margin-bottom: 0;
            background-size: 110%;
            background-position: -5px 40px;
            background-repeat: no-repeat;
            transition: 0s ease-out all;
          }

          &:hover {
            input {
              background-position: -5px -170px;
              transition: 4s ease-out all;
              transition-delay: 9s;
              background-size: 200%;
            }
          }
        }

        .views-submit-button {
          position: absolute;
          margin: 0;
          padding: 0;
          right: 0;
          top: 0;

          .form-submit {
            margin-top: 0;
            padding: 19px;
            font-size: 0;
            background-color: transparent;
            position: relative;
            z-index: 1;
          }

          &:after {
            content: "";
            position: absolute;
            top: 9px;
            right: 8px;
            z-index: 0;
            font-size: 1.4em;
            width: 20px;
            height: 20px;
            background-image: url(/sites/all/themes/aucor/images/search-icon.png);
            background-repeat: no-repeat;
            opacity: 0.3;
          }
        }
      }
    }

    #logo-container {
      flex: 0 0 auto;
      align-self: flex-end;
      line-height: 0%;
    }

    /*
    Ground Floor: 0
     */
    .block-menu > ul.menu {
      display: inline-flex;
      justify-content: space-between;
      line-height: 1;
      height: 100%;
      width: 100%;

      > li {
        flex: 1 1 auto;
        position: relative;
        text-align: center;
        align-self: center;
        padding-left: $gutter;

        &:last-child  {
          padding-right: $gutter;
        }

        &:hover {
          > .menu {
            display: inline-flex;
          }
        }
      }

      a {
        @include condensed-heading();
      }
    }

    .menu-image {
      display: none;
    }

    /*
    Basement: -1
     */

    .block-menu ul.menu > li.first ul.menu{
      width: 555px;
      padding-left: 300px;
      overflow: hidden;
    }

    .block-menu ul.menu > {
      li ul.menu {
        position: absolute;
        left: $gutter;
        width: 100%;
        display: none;
        padding: 0;
        background-color: $bright-red;
        z-index: 4;
        flex-flow: column nowrap;
        align-content: flex-start;
        top: 42px;

        > li {
          flex: 0 0 auto;
          line-height: 1.9;
          text-align: left;
          padding-left: $gutter;

          &:first-child {
            position: static;
            .menu-image {
              display: inline-block;
              position: absolute;
              left: 0;
              top: 0;
            }
          }

          a {
            position: relative;
          }

          &:hover {
            background-color: white;
            position: static;

            a {
              color: $bright-red;
              display: inline-block;

              &:before {
                position: absolute;
                content: "";
                display: inline-block;
                top: 0;
                height: 100%;
                width: 1em;
                left: -1.5em;
                background-color: #ffffff;
              }

              &:after {
                position: absolute;
                content: "";
                display: inline-block;
                height: 1.34em;
                width: 1.34em;
                top: 0;
                transform: rotate(45deg);
                transform-origin: 0 0;
                left: -1em;
                background-color: #ffffff;
              }
            }

            .menu-image {
              display: inline-block;
              position: absolute;
              left: 0;
              top: 0px;
            }
          }
        }
      }
    }
  }
}

.block-system-main-menu-instance {
  img {
    display: none;
  }
}

.gn-menu-wrapper {
  top: 55px;
}

.gn-menu li a {
   &:before {
     content: attr(data-menu-icon);
     color: $medium-gray;
   }

  &:hover {
    background-color: $bright-red;
    color: white;
  }

  &:hover:before {
    color: white;
    transition: 500ms linear all;
    transform: rotateY(20deg) skewX(-19deg) scale(1.5);
  }
}

.gn-menu-wrapper.gn-open-all {
  width: 340px;
  box-shadow: 1em 0em 10px -10px;
}