body.node-type-asset #page-title {
  @include shift(3 of 12);
}

.node-asset.view-mode-full {
  .field-name-asset-slideshow{
    @include image-carousel;
    padding-bottom: $base-spacing;
  }

  .slick-slider {
    width: 90%;
    margin: auto;
  }

  .node-auction h2 {
    display: none;
  }

  .view-header {
    text-align: center;
    position: relative;
    height: 240px;
    background-color: $light;
    margin-bottom: $gutter / 2;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);

    }
  }

  .slick--carousel {
    position: relative;
    @include image-carousel;
    margin-top: 0;
    padding-top: 0;
    background-color: transparent;

    .slick-prev {
      left: -10px;
    }

    .slick-next {
      right: -10px;
    }
  }

  .drop-down {
    color: $bright-red;
    margin-top: $gutter;
  }
}

.group-asset-container {
  border: 1px solid $light-gray;
  padding: $gutter / 2;
  display: inline-block;
  width: 100%;

  @include outer-container;

  .field-name-asset-actions {
    @include span-columns(12 of 12);
  }

  .field-name-asset-s-auction-location {
    @include span-columns(5 of 12);
    @include omega;
  }

  .field-name-asset-slideshow-navigation {
    @include span-columns(7 of 12)
  }

  .group-details {
    @include span-columns(5 of 12);
    @include omega();
    > h3 {
      display: none;
    }
  }
}

.field-name-field-auction-date .field-label {
  color: $bright-red;
  margin-bottom: $base-spacing;
}

a.show-large-image {
  display: inline-block;
  width: 100%;
  height: 100px;
  text-align: center;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    outline: 5px solid white;
  }
}

.field-name-asset-slideshow-navigation {
  width: 60%;
}

.field-name-field-asset-details {
  @include add-drop-down;
  color: $bright-red;
}

.action-links .view-content > div {
  @include action-links;

  .views-field-title {
    text-align: right;
    background: none;
    padding: 0;
    a{color: $bright-red}
    align-content: flex-end;
  }
}

.entity-field-collection-item {
  .content > div {
    float: left;

    &.field-name-field-asset-detail-label {
      font-weight: bold;
      .field-item:after {
        content: ": ";
        margin-right: 0.5em;
      }
    }
  }
}