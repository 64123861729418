.calendar.home {
  @include outer-container;
  padding: 0 $gutter;

  h2 {
    @include condensed-heading($bright-red);
    text-align: center;
    margin: 1.5em auto;
  }

  > .calendar-wrapper {
    margin-bottom: $gutter;

    @include span-columns(6 of 6);
    @include media($medium-screen-up) {
      @include span-columns(4 of 12);
    }
  }

  > .attachment {
    font-size: 0.7em;

    @include span-columns(6 of 6);
    @include media($medium-screen-up) {
      @include span-columns(8 of 12);
    }

    .view-content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
      margin-top: $gutter / -2;

      .views-row {
        flex: 1 1 320px;
        margin-right: 1em;

        &:nth-last-child(1), &:nth-last-child(2) {
          border: none;
        }
      }
      .views-field-field-auction-date {
        flex: 0 0 80px;
      }

      .views-field-title {
        flex: 0 0 calc(100% - 100px);
      }
    }
  }
}
@mixin calendar-reset {
  > .view-header, > .view-content {
    * {
      background-color: black !important;
      border-width: 0 !important;
      text-align: center !important;
      color: white;
      font-weight: normal !important;
    }

    table .mini-day-on a {
      color: $bright-red !important;
    }
  }
}
.calendar {
  &.sidebar {
    width: 100%;
    @include calendar-reset;
  }

  &.home {
    width: 100%;
    > .calendar-wrapper {
      @include calendar-reset;
    }
  }

  &.home > .calendar-wrapper, &.sidebar {
    padding: 1em 0.7em;
    background-color: black;

    .calendar-calendar th.days {
      padding: 0.2em 0;
    }



    .date-nav-wrapper {
      .date-heading h3 {
        line-height: 2;
        font-size: 1em;
        font-family: $base-font-family;
        text-transform: uppercase;
        letter-spacing: 5px;
        transform: translateY(-0.5em);
      }

      .date-nav {
        background-color: transparent;
        border: 0px;
        height: 0px;
        position: relative;
        margin-bottom: 0px;
      }

      .date-prev {
        margin: auto;
        a {
          position: relative;
          margin: auto;
          color: transparent;
          &:after {
            content: "\2039";
            color: white;
            font-size: 4em;
            line-height: 0;
          }
        }
      }

      .date-next {
        margin: auto;
        a {
          position: relative;
          margin: auto;
          color: transparent;
          &:after {
            content: "\203a";
            color: white;
            font-size: 4em;
            line-height: 0;
          }
        }
      }
    }
  }
}

&.calendar.main {
  @import "calendar-multiday";

  &.view {
    display: none;
    width: 100%;

    @include media($large-screen-up) {
      display: block;
    }

    .view-header {
      @include black-gradient;
      height: 100px;
    }
    .view-content {
      margin-top: -31px;
    }
  }
}



#content .view .date-nav-wrapper {
  .date-prev {
    border-radius: 0;
    background: none;
    float: none;
    padding: 5px 0;
    position: absolute;
    left: 0px;
    right: auto;
    text-align: right;
    top: 0px;
    width: auto;
    z-index: 1;
    height: 0;
    a {
      font-size: 0px;
      height: 0;
      &:before {
        content: "\2039";
        font-size: 40px;
        color: white;
        height: 0;
        line-height: 0;
      }
    }
  }

  .date-next {
    border-radius: 0;
    background: none;
    float: none;
    padding: 5px 0;
    position: absolute;
    right: 0px;
    text-align: right;
    top: 0px;
    width: auto;
    z-index: 1;
    a {
      font-size: 0px;
      &:before {
        content: "\203a";
        font-size: 40px;
        color: white;
        height: 0;
        line-height: 0;
      }
    }
  }
}