body.node-type-auction #page-title {
  @include shift(3 of 12);
}

.node-auction.view-mode-full {

  .field-name-field-related-asset-image-carous {
  @include image-carousel;
    background: none;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: $gutter;
  }
}

#auction-enquire {
  display: none;
}

#colorbox {
  #auction-enquire{
    display:block;
  }
} 

.auction-container {
  border: 1px solid $light-gray;
  padding: $gutter / 2;
  display: inline-block;

  $auction-container-split: 65%;
  $auction-container-split-gap: 5%;
  > div {
    width: 100%;
    float: left;

    @include media($large-screen-up) {
      width: $auction-container-split;
    }
  }

  > div.field-name-field-location-lat-lon {
    width: 100%;
    float: right;

    @include media($large-screen-up) {
      width: (100% - ( $auction-container-split + $auction-container-split-gap ));
    }
  }

  > ul {
    @include action-links;
    li:last-child {
      @include background_red_gradient;
    }
  }

  .field-name-notice {
    margin: 0 (-$gutter) / 2 $gutter;
    width: calc(100% + 30px);
    text-align: center;
    font-weight: bold;

    p {
      margin: 0;
      padding: $gutter / 2;

      &:nth-child(1) {
        background: $bright-red;
        color: white;
      }

      &:nth-child(2) {
        background-color: $light;
        color: black;
      }
    }
  }
}

.field-name-lots-on-auction {
  width: 100% !important;
  float: left;
  > .label-above {
    @include condensed-heading()
  }
}

#quicktabs-lots_on_auction {
  .item-list {
    margin-bottom: $gutter;
    &:before {
      content: "Display Auction Lots >";
      color: $medium-gray;
      font-weight: bold;
    }

    ul.quicktabs-tabs {
      float: right;
    }

    li {
      width: 23px;
      display: inline-block;
      height: 23px;
      margin: 0 0px;
      background-repeat: no-repeat;

      a {
        color: transparent;
        display: inline-block;
        height: 100%;
        width: 100%;
      }

      background-image: url(/sites/all/themes/aucor/images/grid-list-inactive.png);

      &.active {
        background-image: url(/sites/all/themes/aucor/images/grid-list-active.png);
      }
    }

    li.first {
      background-position: right;
      margin-right: 0.5em;
    }

    li.last {
      background-position: left;
      display: none;

      @include media($large-screen-up) {
        display: inline-block;
      }
    }
  }
  .node-asset {
    > div {
      float: left;

      &.field-name-auction-teaser-actions {

        ul {
          display: inline-block;
        }

        li {
          display: inline-block;
          float: left;
          margin-right: .5em;
          &:last-child {
            margin-right: 0;
          }

          a {
            @include sleek-button();
          }

          &:nth-child(2) {
            a {
              @include sleek-button($medium-gray);
            }
          }
        }
      }
    }
  }
}

.view-lots-on-auction {
  margin: 0 (-$gutter) / 2;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  .views-row {
    padding: $gutter / 2;
    flex: 1 0 100px;

    &.views-row-odd {
      background-color: $light;
    }
  }


  .node-asset {

    .field-name-field-asset-images {
      text-align: center;
      float: left;
      height: 100px;
      width: 100px;
    }
    .field-name-title {
      float: left;
      width: calc(100% - 100px);
      padding: 0 0 0 $gutter / 2;
      h2 {
        margin-bottom: .5em;
      }
    }

    .field-name-auction-teaser-actions {
      float: left;
      padding: 0 0 0 $gutter / 2;
    }
  }

  table .node-asset .field-name-auction-teaser-actions {
    padding: 0;
    margin-top: .5555em;
  }
}

@import "enquiry-form";


.view-upcoming-auctions, .view-featured-upcoming-auctions {
  .view-content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    width: 100%;
  }

  &.horisontal {
    .view-content {
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .views-row {
      flex: 0 0 30%;

      .views-field-title {
        flex: 1 1 calc(100% - 80px);
      }

      &:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3) {
        border: none;
      }
    }
  }

  .views-row {
    flex: 0 0 auto;
    border-bottom: 1px solid $light-gray;
    padding: $gutter / 2 0;

    &:last-child {
      border: none;
    }

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .views-field-field-auction-date {
      flex: 0 0 60px;
      order: 1;
      @include date-box-red(.9em);
      padding: 0 0.5em;
      margin-right: 1em;
    }

    .views-field-nothing {
      flex: 0 0 60px;
      order: 3;
    }

    .addtocal, .download-catalogue {
      margin-top: 0.5rem;
      padding: 0;
      font-size: 0;
      border-radius: 0;
      border: none;
      color: inherit;
      text-decoration: none;
      cursor: pointer;
      @include cal-background-icon;
      float: right;
      display: inline-block;

    }

    .download-catalogue {
      background-position: left;
      float: left;
      @include pdf-background-icon;
    }

    .views-field-title {
      flex: 1 1 calc(100% - 75px);
      order: 2;

      a {
        @include condensed-heading(black, 1.1em);
      }
    }
  }
}

.view-featured-upcoming-auctions .views-row {
  .views-field-nothing {
    flex: 0 0 35px;
    order: 3;
  }

  .addtocal, .download-catalogue {
    @include cal-background-icon(16px);
  }

  .download-catalogue {
    @include pdf-background-icon(16px);
  }
}

.upcoming_auctions-block_1 {
  h2 {
    margin: 1.5em auto 0;
  }
}