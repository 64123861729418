@import "../base/mixins.scss";

body {
  margin: 0;
  padding: 0;

  main {
    background-color: white;

    h1#page-title {
      font-size: 2.25em;
      margin: 0 0 0.55em;
    }

    @include outer-container;
    padding: 1.5em 0;
    @include avoid-edges;
  }

  &.no-sidebars {
    main {
      section#content {
        @include span-columns(1 of 1); // Mobile first!

        @include media($medium-screen-up) {
          @include span-columns(6 of 6);
        }

        @include media($large-screen-up) {
          @include span-columns(12 of 12);
        }
      }
    }
  }

  &.one-sidebar.sidebar-first {
    main {
      aside {
        &#sidebar-first {
          @include span-columns(1 of 1); // Mobile first!

          @include media($medium-screen-up) {
            @include span-columns(2 of 6);
          }

          @include media($large-screen-up) {
            @include span-columns(4 of 12);
          }
        }
      }

      section#content {
        @include span-columns(1 of 1); // Mobile first!

        @include media($medium-screen-up) {
          @include span-columns(4 of 6);
        }

        @include media($large-screen-up) {
          @include span-columns(8 of 12);
        }
      }
    }
  }

  &.one-sidebar.sidebar-second {
    main {
      aside {
        &#sidebar-second {
          @include span-columns(1 of 1); // Mobile first!

          @include media($medium-screen-up) {
            @include span-columns(2 of 6);
          }

          @include media($large-screen-up) {
            @include span-columns(4 of 12);
          }
        }
      }

      section#content {
        @include span-columns(1 of 1); // Mobile first!

        @include media($medium-screen-up) {
          @include span-columns(4 of 6);
        }

        @include media($large-screen-up) {
          @include span-columns(8 of 12);
        }
      }
    }
  }

  &.two-sidebars {
    main {
      aside {
        &#sidebar-first {
          @include span-columns(1 of 1); // Mobile first!

          @include media($medium-screen-up) {
            @include span-columns(1.5 of 6);
          }

          @include media($large-screen-up) {
            @include span-columns(3 of 12);
          }
        }

        &#sidebar-second {
          @include span-columns(1 of 1); // Mobile first!

          @include media($medium-screen-up) {
            @include span-columns(1.5 of 6);
          }

          @include media($large-screen-up) {
            @include span-columns(3 of 12);
          }
        }
      }
    }

    section#content {
      @include span-columns(1 of 1); // Mobile first!

      @include media($medium-screen-up) {
        @include span-columns(3 of 6);
      }

      @include media($large-screen-up) {
        @include span-columns(6 of 12);
      }
    }
  }

  .outer-wrapper {
    @include outer-container;
  }
}