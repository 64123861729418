#sidebar-first {
  @include clearfix;

  li {
    margin: 0;
    color: $bright-red;

    a {
      color: black;
    }
  }

  label {
    //display: none;
  }

  input[type="checkbox"] {
    float: left;
    margin-top: 2px;
  }

  > section {
    &:not(:first-child) {
      border-top: 1px solid silver;
      padding-top: 1.2em;
    }
    padding-bottom: 1.9em;
  }

  @include sidebar-heading;

}