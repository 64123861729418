/*
 * @file
 * This file is a custom file that loads all files. Each non-base layer
 * can be disabled.
 *
 * Do not name this file "STARTER.scss". This file should be named
 * aucor.scss. If you used the drush command to generate this subtheme,
 * it should be named for you.
 *
 * This application file (aucor.scss) is where all the partials are
 * imported.
 *
 * If you have changed the paths where bower components live, please update the mappings below.
 */

// Make sure the charset is set appropriately
@charset "UTF-8";


@font-face {
  font-family: 'm-Bold-Condensed';
  src: url('/sites/all/themes/aucor/fonts/m-Bold-Condensed.ttf') format('truetype'),
  url('/sites/all/themes/aucor/fonts/m-Bold-Condensed.otf')  format('opentype'),
}

@font-face {
  font-family: 'm-Condensed';
  src: url('/sites/all/themes/aucor/fonts/m-Condensed.ttf')  format('truetype'),
  url('/sites/all/themes/aucor/fonts/m-Condensed.otf') format('opentype'),
}

@font-face {
  font-family: 'm-Light-Condensed';
  src: url('/sites/all/themes/aucor/fonts/m-Light-Condensed.ttf')  format('truetype'),
  url('/sites/all/themes/aucor/fonts/m-Light-Condensed.otf') format('opentype'),
}

@font-face {
  font-family: 'm-Semibold-Condensed';
  src: url('/sites/all/themes/aucor/fonts/m-Semibold-Condensed.ttf')  format('truetype'),
}

// Import normalize.css
@import "../../neato/bower_components/normalize.css/normalize.css";

// Import Bourbon.
@import "../../neato/bower_components/bourbon/app/assets/stylesheets/bourbon";

// Import Bitters.
@import "base/base";

// Import Neat.
@import "../../neato/bower_components/neat/app/assets/stylesheets/neat";
@import "../../neato/bower_components/neat/app/assets/stylesheets/neat-helpers";

// Import layout
@import "base/layout";

// Import Drupal element overrides
@import "common/breadcrumb";
@import "common/messages";
@import "common/tables";
@import "common/tasks";
@import "common/dev";

//@import "common/willem";

// Import your components here.
@import "base/mixins";
@import "components/super";
@import "components/header";
@import "components/sidebar-first";
@import "components/homepage";
@import "components/content";
@import "components/auction";
@import "components/asset";
@import "components/flipclock-override";
@import "components/calculator";
@import "components/calendar";
@import "components/sidebar-second";
@import "components/contact-us";
@import "components/footer";
@import "common/ui.accordion";
@import "components/video-page";