.tabs {
  &.primary {
    ///////////////////////////////////////////////////////////////////////////////////
    $base-border-color: gainsboro !default;
    $base-background-color: white !default;
    $base-spacing: 1.5em !default;
    $base-accent-color: #477DCA !default;
    $base-link-color: $base-accent-color !default;
    $dark-gray: #333 !default;
    $medium-screen: em(640) !default;
    //////////////////////////////////////////////////////////////////////////////////

    $tab-link-color: $dark-gray;
    $tab-border: 1px solid $base-border-color;
    $tab-active-background: $base-background-color;
    $tab-inactive-color: $base-background-color;
    $tab-inactive-hover-color: silver;
    $tab-mode: $medium-screen;

    @include clearfix;

    line-height: 1.5;
    padding: 0;
    margin: 5px 0;
    border-bottom: 1px solid $base-border-color;
    position: fixed;
    bottom: -2.5em;
    transform: translateX(-50%);
    z-index: 2;
    left: 50%;
    transform-origin: top left;
    box-shadow: 2px 2px 19px -6px black;
    background-color: $base-background-color;
    opacity: .98;
    border-top: 5px groove red;

    &:after {
      content: "Edit stuff here.";
      position: absolute;
      right: 1em;
      top: -1em;
      border: 1px solid red;
      font-size: 0.8em;
      padding: 0em 0.4em;
      border-radius: 2px;
      background: red;
      color: white;
      box-shadow: 1px 1px 9px -2px black;
    }

    &:hover {
      opacity: 1;
      bottom: -0.4em;
      transition: 666ms linear all;
    }

    li {
      list-style: none;

      @include media($tab-mode) {
        display: inline;
      }

      a {
        background-color: $tab-inactive-color;
        border-top: $tab-border;
        color: $tab-link-color;
        display: block;
        font-weight: 400;
        padding: $gutter / 3;
        text-decoration: none;

        @include media($tab-mode) {
          @include inline-block;
          border-top: 0;
        }

        &:hover {
          color: $base-link-color;
        }

        &:focus {
          outline: none;
        }

        &.active {
          border-bottom: 0;

          @include media($tab-mode) {
            border: $tab-border;
            border-bottom-color: $tab-active-background;
            margin-bottom: -1px;
          }
        }
      }

      .tab-content {
        display: none;
        padding: $base-spacing $gutter;
        width: 100%;

        @include media($tab-mode) {
          border-top: $tab-border;
          float: left;
        }
      }
    }
  }
}