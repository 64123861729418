// Typography
$base-font-family: "m-Condensed", $helvetica;
$heading-font-family: "m-Bold-Condensed", $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 2px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$dark-red: #94111a;
$bright-red: #ea1c24;
$dark-gray: #4f4f4f;
$medium-gray: #666666;
$light-gray: #999999;
$dark: #262626;
$light: #ececec;

// Font Colors
$base-background-color: #fff;
$base-font-color: black;
$action-color: $bright-red;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);
