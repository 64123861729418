@mixin avoid-edges () {
  $avoid-edges: $max-width + em($gutter) * 2;
    padding-left: $gutter;
    padding-right: $gutter;
}

@mixin condensed-heading ($color: $action-color, $font-size: 1.3em) {
  font-weight: 600;
  font-size: $font-size;
  color: $color;
}

//Gradients
@mixin background_red_gradient() {
  background: linear-gradient(to bottom,  #ed1c24 0%,#da1a22 27%,#ac141c 68%,#9a101a 88%,#94101a 100%,#9b2029 100%);
}

@mixin black-gradient() {
  background: linear-gradient(to bottom, #646464 0%,#585758 23%,#2b2b2c 79%,#212121 100%);
}

@mixin aucor-button ($color: white) {
  padding: 0;
  display: inline-block;
  color: $color;
  font-size: .9em;
  border-radius: 0;

  a {
    color: $color;
    padding: 0.4em 1em 0.2em;
    display: inline-block;
    width: 100%;
    border-radius: 0;

    &:hover {
      filter: brightness(110%);
      width: 100%;
      box-shadow: 5px 5px 20px -5px black inset;
      transition : {
        duration: 800ms;
        timing-function: linear;
        property: all;
      }
    }
  }
}

@mixin hover-link {
  a {
    display: inline-block;
    &:hover {
      transition: 1s ease transform;
      transform: translateX(1em);
    }
  }
}

@mixin social-media () {
  .social-media-links {
    flex: 0 2 25px;
    font-size: 0;
    height: 25px;
    min-width: 15px;
    margin: 0 5px;
    background-image: url(/sites/all/themes/aucor/images/super-sprite.png);
    background-repeat: no-repeat;
  }

  a#facebook {
    background-position: -54px 0px;
    width: 15px;
  }

  a#twitter {
    background-position: -78px 0px;
    width: 43px;
  }

  a#googleplus {
    background-position: -107px 0px;
    width: 21px;
  }

  a#youtube {
    background-position: -137px 0px;
    width: 16px;
  }

  a#linkedin {
    background-position: -167px 0;
  }

  a#instagram {
    background-position: -197px 0;
    width: 20px;
  }
}

@mixin add-drop-down ($color: black) {
  cursor: pointer;
  &:after {
    content: "\2039";
    color: $color;
    transform: rotate(-90deg) translateX(0.3em);
    display: inline-block;
    float: right;
    margin-right: 30px;
    font-size: 2em;
    transform-origin: center;
    transition: 1s ease all;
  }

  &.closed:after, &.ui-accordion-header-active:after {
    transform: rotate(-180deg) translateX(0em) translateY(0.4em);
  }
}

@mixin add-arrow-after ($color: $bright-red) {
  position: relative;
  &:after {
    content: "\2039";
    color: $color;
    font-size: 1.8em;
    line-height: 0.8em;
    position: absolute;
    margin-left: 0.3em;
    transform: rotate(180deg);
  }

  &:hover:after {
    transition: 750ms linear all;
    margin-left: 0.4em;
  }
}

@mixin sidebar-heading {
  h2 {
    @include condensed-heading(black);

    font-size: 1.2em;

    @include add-drop-down;
  }
}

@mixin date-box-red($font-size: 1em) {
  @include background_red_gradient;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: $font-size;

  .time {
    float: left;
    clear: both;
    display: inline-block;
    width: 100%;
    line-height: 0.8;
    font-size: 1.3em;
  }

  span.date-display-single {
    display: inline-block;
    font-size: 1.1em;
    padding: 0.3em 0;
    text-shadow: 0 1px black;
  }
}

@mixin image-carousel {

  div:focus {
    outline: none;
  }

  background-color: $light;
  margin-top: $base-spacing;
  padding-top: $base-spacing;
  padding-bottom: 0;
  button {
    color: transparent;
  }

  .slick__arrow { //Parent container.
    position: absolute;
    width: 100%;
    top: 50px;
  }

  .slick-arrow { //Button element.
    position: absolute;
    z-index: 1;
    height: 15px;
    border-radius: 0;
    background-color: transparent;
    font-size: 0;

    &::after {
      position: absolute;
      color: $dark-gray;
      font-size: 40px;
      top: -0.5em;
    }
  }

  .slick-prev { //Button element.
    left: 0;
    &::after {
      content: "\2039";
      left: 10px;
    }
  }

  .slick-next { //Button element.
    right: 0;
    &::after {
      content: "\203a";
      right: 10px;
    }
  }
}

@mixin background-icon($size: 26px) {
  content: "";
  height: $size;
  width: $size;
  background-image: url(/sites/all/themes/aucor/images/pdf-cal-sprite.png);
  background-color: transparent;
  background-size: cover;
  display: inline-block;
}

@mixin pdf-background-icon($size: 26px) {
  @include background-icon($size);
  background-position: left;
}

@mixin cal-background-icon($size: 26px) {
  @include background-icon($size);
  background-position: right;
}

@mixin sleek-button($color: $bright-red) {
  border: 1px solid;
  padding: 0.15em .5em 0em;
  line-height: 1.5;
  color: $color;

  &:hover {
    background-color: $color;
    color: white;
    border: 1px solid darken($color, 25%);
  }
}

@mixin action-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: $gutter;

  li, > * {
    flex: 1 1 auto;
    margin: 0 1px 5px 0;
    text-align: center;

    @include aucor-button();
    @include black-gradient();
    @include condensed-heading(white, 1.2em);


  }
}

@mixin close-button ($color: white) {
  background: transparent;
  &:after {
    content: "+";
    color: transparentize($color, .2);
    font-size: 30px;
    display: block;
    transform-origin: center;
    transform: rotate(45deg);
    transition: 800ms ease-in all;
  }

  &:hover {
    background: transparent;
    &:after {
      transform: rotate(135deg);
      transition: 800ms linear all;
      color: invert(transparentize($color, .2));
    }
  }
}