#sidebar-second {
  > section {
    margin-bottom: $gutter;
  }
}

#sidebar-second {
  @include sidebar-heading;
}

#sidebar-second .has-major-heading, #sidebar-first .has-major-heading {
  h2 {
    margin-top: -1.8em;
    font-size: 2.1em;

    &:after {
      display: none;
    }
  }
}