#super {
  background-color: $dark;
  height: 55px;

  .outer-wrapper {
    @include media($medium-screen-up) {
      @include avoid-edges;
    }
  }

  .social-media-container {
    display: flex;
    flex-flow: row nowrap;
    align-content: space-between;
    float: right;
    transform: translateY(11%);

    @include media($medium-screen-up) {
      transform: translateY(50%);
    }
  }

  @include social-media;
}

$currency-item-width: 170px;

@keyframes currency-scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-$currency-item-width * 3);
  }
}

.currency.block {
  clear: both;
  transform: translateY(50%);
  margin-right: 0.4em;

  @include media($medium-screen-up) {
    transform: translateY(110%);
    margin-right: 1em;
    clear: none;
  }

  width: $currency-item-width;
  max-width: $currency-item-width;
  overflow: hidden;
  display: inline-block;
  float: right;
  line-height: 3;
  font-size: 1.2em;
  text-align: center;
  background-image: url("/sites/all/themes/aucor/images/zar.png");
  background-position: right 45%;
  background-repeat: no-repeat;


  .currency-inner {
    width: $currency-item-width * 3;
    animation: {
      name: currency-scroll;
      timing-function: steps(3);
      iteration-count: infinite;
      duration: 15s;
      direction: normal;
      fill-mode: forwards;
    }
    .currency-item {
      width: $currency-item-width;
      float: left;
      display: inline-block;
      color: $light;
      background-position: left 45%;
      background-repeat: no-repeat;
      height: 17px;
      line-height: 1;

      &.gbp {
        background-image: url("/sites/all/themes/aucor/images/gbp.png");
      }

      &.usd {
        background-image: url("/sites/all/themes/aucor/images/usd.png");
      }

      &.eur {
        background-image: url("/sites/all/themes/aucor/images/eur.png");
      }
    }
  }
}