#site-footer {
  section {
    @include outer-container;
  }

  padding: $base-spacing $gutter;
  @include media($medium-screen-up) {
    padding: ($base-spacing * 2) $gutter;
  }

  width: 100%;

  @include background_red_gradient();

  /*
  The responsive menu's block is hidden in the
  footer.
   */
  .gn-menu-main {
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    height: 55px;
    font-size: 13px;
    float: left;
  }
}

.footer-top {
  width: 100%;
}

.menu-footer-menu, .menu-second-footer-menu { //block level
  width: 50%;
  @include media($medium-screen-up) {
    width: 65%;
  }

  display: inline-block;
  a {
    color: white;
  }

  > .menu {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    
    li {
      border-top: 1px solid rgba(255,255,255,.5);
      padding: .4em 0;
      .menu {
        display: inline-block;
        @include hover-link;
      }
    }

    > li{
      flex: 0 1 auto;
      border-top: none;

      > a {
        @include condensed-heading(white);
        display: block;
      }
    }

  }
}

.menu-second-footer-menu {
  width: 50%;
  float: right;
  @include media($medium-screen-up) {
    width: 20%;
  }
  > .menu {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;

    > li{
      flex: 1 0 100%;
      border-top: none;

      > a {
        @include condensed-heading(white);
        display: block;
      }
    }

  }
}

.footer-social-media {
  display: flex;
  flex-flow: row nowrap;
  align-content: space-between;
  width: 13em;
  a {
    filter: brightness(200%);
  }
  @include social-media;
}

#saia {
  font-size: 0;
  width: 100px;
  height: 100px;
  background-image: url("/sites/all/themes/aucor/images/SAIA-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
}