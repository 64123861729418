/**
 * calendar calendar table styles
 */

.calendar-calendar {
  tr {
    &.odd, &.even {
      background-color: #fff;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 auto;
    padding: 0;
    width: 100%;

    /* Setting at 100% causes problem in Internet Explorer. */
  }

  .month-view table {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .year-view td {
    width: 32%;
    padding: 1px;
    border: none;

    table td {
      width: 13%;
      padding: 0;
    }
  }

  tr {
    padding: 0;
    margin: 0;
    background-color: transparent !important;

    &.date-box, td {
      height: 0;
      max-height: 0;
    }
  }

  th {
    text-align: center;
    margin: 0;

    a {
      font-weight: bold;
    }
  }

  td {
    width: 14%;
    border: 1px solid #ccc;
    color: #777;
    text-align: right;
    vertical-align: top;
    margin: 0;
    padding: 0;
  }

  .mini {
    border: none;
  }

  td.week {
    width: 1%;
    min-width: 1%;
  }

  .week {
    clear: both;
    font-style: normal;
    color: #555;
    font-size: 0.8em;
  }

  .inner {
    //height: 0 !important;
    padding: 0;
    margin: 0;

    div {
      padding: 0;
      margin: 0;
    }

    p {
      padding: 0 0 .8em 0;
      margin: 0;
    }
  }

  td {
    a {
      font-weight: bold;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &.year, &.month {
      text-align: center;
    }
  }

  th.days {
    color: #ccc;
    background-color: #224;
    text-align: center;
    padding: 1px;
    margin: 0;
  }

  div {
    &.day {
      float: right;
      text-align: center;
      padding: 0;
      margin: 0;
      background-color: #f3f3f3;
      border: solid gray;
      border-width: 0 0 1px 1px;
      clear: both;
      font-size: 1.1em;
      color: white;
    }

    &.calendar {
      background-color: #fff;
      border: solid 1px #ddd;
      text-align: left;
      margin: 0 .25em .25em 0;
      width: 96%;
      float: right;

      /* needed to keep IE from hiding the floating day number */
      clear: both;
    }
  }

  .day-view div.calendar {
    float: none;
    width: 98%;
    margin: 1% 1% 0 1%;
  }

  div.title {
    font-size: .8em;
    text-align: center;

    a {
      color: #000;

      &:hover {
        color: #c00;
      }
    }
  }

  .content {
    clear: both;
    padding: 3px;
    padding-left: 5px;
  }
}

/**
 * IE needs a little help to get the side by side tables to the right size
 */

/**
 * simplify the mini calendar by removing borders
 */

/**
 * format for the week number in the first cell
 */

/**
 * IE6 does not support min-height, using Min-Height fast hack from http://www.dustindiaz.com/min-height-fast-hack
 */

/**
 * Make sure paragraphs buried in calendar cells use padding, not margins, for separation so the background color doesn't come through.
 */

/**
 * format days of the week header cells
 */

/**
 * floating day number div
 */

/**
 * individual node container
 */

/**
 * No floating of day view content because there is no floating day number
 */

.calendar div.form-item {
  white-space: normal;
}

table {
  td.mini, th.mini, &.mini td.week {
    padding: 0 1px 0 0;
    margin: 0;
  }

  td.mini a {
    font-weight: normal;
  }
}

.calendar-calendar {
  .mini-day-off, .mini-day-on {
    padding: 0px;
  }
}

table .mini-day-on a {
  text-decoration: underline;
}

.calendar-calendar .mini .title {
  font-size: .8em;
}

.mini .calendar-calendar .week {
  font-size: .7em;
}

.mini-row {
  width: 100%;
  border: none;
}

.mini {
  width: 32%;
  vertical-align: top;
}

/**
 * formatting for the legend stripe and block
 */

.calendar-calendar {
  .stripe {
    height: 5px;
    width: auto;
    font-size: 1px !important;
    line-height: 1px !important;
  }

  .day-view .stripe {
    width: 100%;
  }
}

table.calendar-legend {
  background-color: #ccc;
  width: 100%;
  margin: 0;
  padding: 0;

  tr {
    &.odd .stripe, &.even .stripe {
      height: 12px !important;
      font-size: 9px !important;
      line-height: 10px !important;
    }
  }
}

.calendar-legend td {
  text-align: left;
  padding-left: 5px;
}

.calendar-empty {
  font-size: 1px;
  line-height: 1px;
}

/**
 * formatting for the full day view
 */

.calendar-calendar {
  td {
    &.calendar-agenda-hour {
      text-align: right;
      border: none;
      border-top: 1px solid #CCCCCC;
      padding-top: .25em;
      width: 1%;
    }

    &.calendar-agenda-no-hours {
      min-width: 1%;
    }

    &.calendar-agenda-hour {
      .calendar-hour {
        font-size: 1.2em;
        font-weight: bold;
      }

      .calendar-ampm {
        font-size: 1em;
      }
    }

    &.calendar-agenda-items {
      border: 1px solid #CCCCCC;
      text-align: left;

      div.calendar {
        width: auto;
        padding: .25em;
        margin: 0;
      }
    }
  }

  div.calendar div.inner .calendar-agenda-empty {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    padding: 1em 0;
    background-color: #fff;
  }
}

/**
 * the popup date selector for jumping to a new date
 */

.calendar-date-select {
  form {
    text-align: right;
    float: right;
    width: 25%;
  }

  div, input, label {
    text-align: right;
    padding: 0;
    margin: 0;
    float: right;
    clear: both;
  }

  .description {
    float: right;
  }
}

.calendar-label {
  font-weight: bold;
  display: block;
  clear: both;
}

.calendar-calendar {
  div.date-nav {
    background-color: #ccc;
    color: #777;
    padding: 0.2em;
    width: auto;
    border: 1px solid #ccc;

    a, h3 {
      color: #777;
      text-decoration: none;
    }
  }

  th.days {
    background-color: transparent;
    color: white;
    font-weight: bold;
    border: none;
  }

  td.empty {
    background: #ccc;
    border-color: #ccc;
    color: #cc9;
  }

  table.mini td.empty {
    background: #fff;
    border-color: #fff;
  }

  td {
    div.day {
      border: 1px solid #ccc;
      border-top: none;
      border-right: none;
      margin-bottom: 2px;
    }

    .inner div {
      background: #eee;

      a {
        background: #eee;
      }
    }
  }

  div.calendar {
    border: none;
    font-size: x-small;
  }

  td {
    .inner {
      div.calendar div {
        border: none;

        a {
          border: none;
          background: #ffc;
          padding: 0;
        }

        &.calendar-more {
          color: #444;
          background: #fff;
          text-align: right;

          a {
            color: #444;
            background: #fff;
            text-align: right;
          }
        }
      }
      .views-field-title{
        padding: 0 2em 0 0;
        font-size: 1.4em;
      }
      .views-field-field-auction-date, .views-field-field-auction-location {
        color: $light;
        display: inline;
        font-size: 1.4em;
        line-height: 0;

        * {
          display: inline;
        }
      }

      .view-field {
        color: #444;
        font-weight: normal;

        a {
          color: #444;
          font-weight: normal;
        }
      }
    }

    span {
      &.date-display-single, &.date-display-start, &.date-display-end, &.date-display-separator {
        font-weight: bold;
      }
    }

    .inner div.day a {
      color: #4b85ac;
    }
  }

  tr {
    td.today, &.odd td.today, &.even td.today {
      background-color: #C3D6E4;
    }
  }

  td {
    .inner div.calendar div div.view-data-node-data-field-date-field-date-value, &.past, &.future, &.has-events, &.has-no-events {}
  }

  tbody {
    border-top: none;
  }

  .month-view .full .inner {
    height: 155 !important;
    min-height: 115px !important;
  }

  .week-view .full {
    .multi-day .inner {
      height: auto;
      min-height: auto;
    }

    .calendar-agenda-hour {
      .calendar-calendar .month-view .full .single-day .inner .view-item {
        float: left;
        width: 100%;
      }

      padding-right: 2px;
    }
  }

  .day-view .full .calendar-agenda-hour {
    padding-right: 2px;
  }

  .week-view .full {
    .calendar-agenda-hour {
      width: 6%;
      min-width: 0px;
    }

    .days {
      width: 13%;
    }
  }

  .month-view .full div.calendar, .week-view .full div.calendar, .day-view div.calendar {
    width: auto;
  }

  .month-view .full {
    tr {
      &.date-box, &.multi-day {

        td {
          position: relative;
          height: 0px;
          max-height: 0px;
        }
      }
    }


  }

  .week-view .full td, .day-view td {
    vertical-align: top;
    padding: 1px 2px 0 2px;

  }

  .month-view .full {
    td.date-box {
      height: 1%;
      border-bottom: 0px;
      padding-bottom: 2px;
    }

    .week {
      font-size: inherit;

      a {
        color: #4b85ac;
      }
    }
  }

  .week-view .full .week a {
    color: #4b85ac;
  }

  .month-view .full td {
    .inner div.day {
      border: none;
      background: none;
      margin-bottom: 0px;

      a {
        border: none;
        background: none;
        margin-bottom: 0px;
      }
    }

    &.date-box .inner {
      min-height: inherit;
    }
  }

  .week-view .full td.date-box .inner {
    min-height: inherit;
  }

  .month-view .full td.multi-day {
    border-top: 0px;
    border-bottom: 0px;
  }

  .week-view .full {
    td.multi-day {
      border-top: 0px;
      border-bottom: 0px;
    }

    .first td.multi-day {
      border-top: 1px solid #CCCCCC;
    }
  }

  .month-view .full td {
    &.single-day {
      border-top: 0px;
      //height: 114px !important;
    }

    &.multi-day .inner {
      min-height: inherit;
      width: auto;
      position: relative;
    }
  }

  .week-view .full td.multi-day .inner, .day-view .full td.multi-day .inner {
    min-height: inherit;
    width: auto;
    position: relative;
  }

  .month-view .full td {
    &.multi-day.no-entry {
      min-height: 0px;
    }

    &.single-day {
      .calendar-empty, &.empty {
        background: rgb(253, 253, 253);

        &.past, &.future {
          background: hsla(0, 0%, 99%, 0) !important;
        }
      }

      &.today {
        .inner div , .inner a {
          color: $bright-red;

          &.views-field-field-auction-location .field-content {
            color: $medium-gray;
          }
        }
      }
    }

    &.date-box.empty {
      background: #F4F4F4;
    }

    &.single-day .inner div, &.multi-day .inner div, .inner div.calendar.monthview div {
      background: none;

      a {
        background: none;
        color: white;
      }
    }

    &.single-day.today .inner div.calendar.monthview  {
      > div {
        margin-top: 0em;
      }
      a {
        color: $bright-red;
      }
    }
  }

  .week-view .full td {
    &.single-day .inner div, &.multi-day .inner div, .inner div.calendar.weekview div {
      background: none;

      a {
        background: none;
      }
    }
  }

  .day-view .full td .inner div {
    &.view-item {
      background: none;
    }

    &.calendar div {
      background: none;

      a {
        background: none;
        margin: 0px 3px;
      }

      margin: 0px 3px;

      &.stripe {
        margin: 0px;
      }
    }
  }

  .month-view .full {
    tr {
      td.today, &.odd td.today, &.even td.today {
        background: none;
        border-left: 2px solid #7C7F12;
        border-right: 2px solid #7C7F12;
      }
    }

    td.date-box.today {
      border-width: 2px 2px 0px 2px;
      border-style: solid;
      border-color: #7C7F12;
    }

    tr td {
      &.single-day.today {
        border-bottom: 2px solid #7C7F12;
      }

      &.multi-day {
        &.starts-today {
          border-left: 2px solid #7C7F12;
        }

        &.ends-today {
          border-right: 2px solid #7C7F12;
        }

        border-top: 0px;
      }

      &.single-day {
        border-top: 0px;
      }

      &.multi-day, &.date-box {
        border-bottom: 0px;
        padding: 0;

        .day {
          background-color: rgba(142, 142, 142, 0.65);
          color: #fff;
          height: 23px;
          line-height: 1.3;
          width: 23px;
          border-radius: 0 0 0 3px;


        }

        &.today {
            border: none !important;
          .day {
            background-color: $bright-red;
            width: 60px;
            font-size: 1em;
            height: 21px;

            &:before {
              content: "Today";
            }
          }
        }
      }
    }

    .inner .monthview {
      -moz-border-radius: 5px;
      border-radius: 5px;
      width: auto;
      float: none;
      display: block;
      margin: .25em auto;
      position: relative;
    }
  }

  .week-view .full .inner .weekview, .day-view .full .inner .dayview {
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: auto;
    float: none;
    display: block;
    margin: .25em auto;
    position: relative;
  }

  .month-view .full td.single-day div.monthview, .week-view .full td.single-day div.weekview, .day-view .full td.single-day div.dayview {
    background: transparent;
    width: auto;
    padding: 0px 5px;
    overflow: hidden;
  }

  .month-view .full td.single-day .calendar-more div.monthview {
    background: none;
  }

  .day-view td div.dayview {
    padding: 0px;
  }

  .month-view .full td.multi-day div.monthview, .week-view .full td.multi-day div.weekview, .day-view .full td.multi-day div.dayview {
    background: #74a5d7;
    height: 1.9em;
    overflow: hidden;
    margin: 0px auto;
    color: #ffffff;
    position: relative;
  }

  .week-view .full td.multi-day div.weekview {
    height: 3.5em;
  }

  .month-view .full td.multi-day .inner .view-field, .week-view .full td.multi-day .inner .view-field, .day-view .full td.multi-day .inner .view-field {
    color: #ffffff;

    a {
      color: #ffffff;
    }
  }

  .full td {
    &.multi-day .calendar .view-field, &.single-day .calendar .view-field {}
  }

  .day-view .full td.multi-day div.dayview, .week-view .full td.multi-day div.weekview {
    margin-bottom: 2px;
  }

  .month-view .full td.multi-day .calendar.monthview .view-field {
    white-space: nowrap;
    float: left;
    margin-right: 3px;
  }

  .week-view .full td.multi-day .calendar.weekview .view-field {
    white-space: nowrap;
    display: inline;
    margin-right: 3px;
  }

  .day-view .full td.multi-day .calendar.weekview .view-field {
    display: block;
  }

  .month-view .full td.multi-day .calendar.monthview .contents, .week-view .full td.multi-day .calendar.weekview .contents {
    position: absolute;
    width: 3000px;
    left: 5px;
  }

  .day-view td .stripe, .month-view .full td .stripe, .week-view .full td .stripe {
    -moz-border-radius: 5px 5px 0px 0px;
    border-radius: 5px 5px 0px 0px;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 3px;
    z-index: 2;
  }

  .full td.single-day {
    .continuation, .continues, .cutoff {
      display: none;
    }
  }

  .month-view .full td.multi-day .inner .monthview .continuation {
    float: left;
    margin-right: 3px;
    height: 1.9em;
  }

  .week-view .full td.multi-day .inner .weekview .continuation {
    float: left;
    margin-right: 3px;
    height: 1.9em;
    height: 2.75em;
    padding-top: 0.75em;
    margin-right: 8px;
  }

  .month-view .full td.multi-day .inner .monthview {
    .continues, .cutoff {
      position: absolute;
      right: 0px !important;
      right: -1px;
      width: 10px;
      text-align: left;
      background: #74a5d7;
      -moz-border-radius: 0px 5px 5px 0px;
      border-radius: 0px 5px 5px 0px;
      height: 1.9em;
      padding-left: 6px;
      z-index: 1;
    }
  }

  .week-view .full td.multi-day .inner .weekview {
    .continues, .cutoff {
      position: absolute;
      right: 0px !important;
      right: -1px;
      width: 10px;
      text-align: left;
      background: #74a5d7;
      -moz-border-radius: 0px 5px 5px 0px;
      border-radius: 0px 5px 5px 0px;
      height: 1.9em;
      padding-left: 6px;
      z-index: 1;
    }

    .continues, .cutoff {
      height: 2.75em;
      padding-top: 0.75em;
    }
  }

  .month-view .full td.multi-day .inner .monthview .cutoff {
    width: 8px;
    padding-left: 0px;
  }

  .week-view {
    .full td.multi-day {
      .inner .weekview .cutoff {
        width: 8px;
        padding-left: 0px;
      }

      padding: 2px;
    }

    td.single-day div.calendar {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }

    .full tr.last td.multi-day {
      border-bottom: 1px solid #CCCCCC;
    }
  }
}

/**
 * Set small font size for all items in calendar div, themes can override this.
 * Use x-small instead of em to avoid multiplying effect in nested items.
 */

/**
 * The following are not used by default but are available for themes
 */

/**
 * Multi day styles
 */

/**
 * Restyle Header
 */

.view .date-nav-wrapper {
  .clear-block {
    margin-bottom: 10px;
  }

  position: relative;
  margin-top: 5px;
  width: 100%;

  .date-nav {
    background-color: transparent;
    border: 0px;
    height: 30px;
    min-height: 30px;
    position: relative;
    margin-bottom: 10px;
  }

  .date-prev a, .date-next a {
    text-decoration: none;
    color: inherit;
    font-size: 12px;
  }

  &.date-nav a:hover {
    text-decoration: underline;
  }

  .date-prev {
    -moz-border-radius: 5px 0 0 5px;
    border-radius: 5px 0 0 5px;
    background: none repeat scroll 0 0 #dfdfdf;
    float: none;
    padding: 5px 0;
    position: absolute;
    right: 60px;
    left: auto;
    text-align: right;
    top: 0px;
    width: auto;
    z-index: 1;
    font-size: 12px;
  }
}

.block-views .view .date-nav-wrapper .date-prev {
  left: 0;
  right: auto;
}

.view .date-nav-wrapper {
  .date-prev a {
    margin-left: 10px;
    font-weight: bold;
  }

  .date-heading {
    position: relative;
    width: 100%;
    top: 0px;
    text-align: center;
    z-index: 0;

    h3 {
      line-height: 30px;
      font-size: 1.7em;
    }
  }

  .pager {
    top: 30px;
    position: absolute;
    width: 100%;
  }

  .date-next {
    -moz-border-radius: 0px 5px 5px 0px;
    border-radius: 0px 5px 5px 0px;
    background: none repeat scroll 0 0 #dfdfdf;
    float: none;
    padding: 5px 0;
    position: absolute;
    right: 0px;
    text-align: right;
    top: 0px;
    width: auto;
    z-index: 1;
    font-size: 12px;

    a {
      margin-right: 10px;
      font-weight: bold;
    }
  }
}

.attachment .calendar-calendar {
  margin-top: 20px;
  clear: both;
}

.calendar-calendar th a {
  background-color: transparent;
  border: 0px;
}

.attachment .calendar-calendar th {
  background-color: transparent;
  border: 0px;

  &.calendar-agenda-hour {
    color: #777777;
    font-weight: bold;
    text-align: right;
  }
}

.view-calendar .feed-icon {
  margin-top: 5px;
}

.view table.mini {
  .date-prev, .date-next {
    background: none;
  }
}

.date-nav {
  width: auto;
  margin: auto;
  padding-top: 1.2em;
  color: white;
  div {
    &.date-prev, &.date-next {
      width: auto;
    }
  }

  width: auto;
}

ul.calendar-links, .region-content ul.calendar-links {
  margin: 0;
  padding: 0;
}

.year-view div.month-view div.date-nav {
  background-color: #DFDFDF;
  min-height: 20px;
}

td.date-box {
  position: relative;

  .inner {
    position: absolute;
    z-index: 3;
    right: 0px;
  }
}

td.single-day {
  @include background_red_gradient;
  position: relative;
  padding-top: 1.1em;

  &.past {
    @include black-gradient;
  }

  &.no-entry {
    background: $light;
  }

  &.empty {
    background: lighten($light, 5%);
  }

  &.today {
    border: none !important;
  }

  .inner {
    z-index: 2;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
  }

  .item {
    flex: 0 1 33%;
    display: none;
    &:nth-child(1) {
      display: block;
    }
    &:nth-child(2) {
      display: block;
    }
  }
}

td[headers="Sunday"], td[headers="Saturday"] {
  background-color: $light-gray !important;;
}

&.view .date-nav-wrapper {
  position: relative;
  margin-top: 0;
  width: 100%;
}

span.date-display-single {
  color: white;
}

.views-field-field-auction-location div.field-content {
  color: white;
}