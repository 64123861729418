.search-filter-tags {
  width: 100%;
  margin-top: -3.8em;
  font-size: 0.9em;
  margin-bottom: 1.2em;

  @include clearfix;

  .item-list ul {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    width: 100%;

    li {
      flex: 0 0 auto;
      margin: 0 .5em;
      padding: 0.18em 0.6em .1em;
      border-radius: 2px;
      background-color: $light;
      color: $action-color;
      font-size: 0.9em;
      line-height: 1.4;

      a {
        color: transparent;
        font-size: 0;
        &:after {
          content: "\2a2f";
          color: black;
          font-size: 1.7rem;
          line-height: 0;
        }
      }

      &:hover {
        color: black;
        a:after {
          color: $action-color;
        }
      }
    }
  }

  h2 {
    float: left;
    line-height: 1.9;
    margin-right: 0.5em;
    font-family: $base-font-family;
    font-weight: 100;

    &::after {
      content: ":";
    }
  }

  .current-search-item {
    float: left;
  }
}

/**
 * Auction Listing / Search results
 **/

.view-display-id-page.auctions .view-content {
  display: flex;
  flex-flow: column nowrap;

  .slick-track {
    height: 140px;
    padding: 15px 0 0;
  }

  .auction {
    flex: 0 1 auto;
    border: 1px solid black;
    margin-bottom: $base-spacing;
    .view-mode-teaser {
      padding-top: $gutter / 2;
      position: relative;

      > :not(.field-name-field-related-asset-image-carous) {
        padding: 0 $gutter / 2;
      }
    }

    h2 {
      font-size: 2em;
      max-width: 70%;
      letter-spacing: -1px;
      a {
        color: black;
      }
    }

    .more-details {
      position: static;

      @include media($large-screen-up) {
        position: absolute;
      }
      right: $gutter / 2;
      top: 0;
    }

    .field-name-field-auction-date {
      @include date-box-red;
    }

    .field-name-node-link {
      background-color: $light-gray;
      text-align: center;

      a {
        padding: 0em;
        @include condensed-heading(white, .9em);
        height: 1.5em;
        display: inline-block;
        width: 100%;
      }
    }

    .field-name-view-catalogue {
      background-color: $medium-gray;
      text-align: center;

      a {
        padding: 0em;
        @include condensed-heading(white, .9em);
        height: 1.5em;
        display: inline-block;
        width: 100%;
      }
    }
    .field-name-field-related-asset-image-carous {
      @include image-carousel;
    }
  }
}

#content {
  font-size: 1.2em;

  article .field-name-field-image {
    float: left;
    margin-right: 1em;
  }

  .field-content, p {
    li {
      padding-left: 1em;
      margin-bottom: 0.4em;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        border: 6px solid;
        top: 0.3em;
        left: 0px;
        border-color: transparent transparent transparent $bright-red;
      }
    }
  }

  button:focus {
    outline: none;
  }
}

.slick-slide {
  img {
    display: block;
    margin: auto;
  }

  .views-field-title {
    text-align: center;

    a {
      color: black;
      font-size: .65em;
      text-transform: uppercase;
      line-height: 1.3;
      display: inline-block;
      padding-top: 8px;
      overflow: hidden;
      white-space: nowrap;
      padding: 8px 6px 0;
      max-width: 80%;
    }
  }
}

#home-category-dots-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  $background-width: -134px;
  article {
    flex: 1 1 130px;
    margin: $gutter / 2;
    text-align: center;
    font-size: .8em;

    .red-dot {
      display: block;
    }

    &:nth-child(2) {
      .red-dot{
        background-position: $background-width 0;
      }
    }

    &:nth-child(3) {
      .red-dot{
        background-position: $background-width * 2 0;
      }
    }

    &:nth-child(4) {
      .red-dot{
        background-position: $background-width * 3 0;
      }
    }

    &:nth-child(5) {
      .red-dot{
        background-position: $background-width * 4 0;
      }
    }
  }

  h3 a {
    font-size: 1.1em;
  }

  li {
    line-height: 1;

    a {
      font-size: .9em;
      color: $medium-gray;
    }
  }

  .red-dot {
    height: 127px;
    width: 137px;
    margin: auto auto 1em;
    background-image: url("/sites/all/themes/aucor/images/category-red-icon-sprite.png");
    background-repeat: no-repeat;
    transition: 666ms ease-in all;
    filter: drop-shadow(0px 1px 1px);

    &:hover {
      filter: drop-shadow(0px 5px 6px) brightness(120%);
    }
  }
}

.block.auction_tips-block {
  @include outer-container;
  background-image: url("/sites/all/themes/aucor/images/auction-guide-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  > p {
    padding: 0 $gutter;
    color: white;
    text-align: center;
    letter-spacing: 2px;
  }

  h2 {
    text-align: center;
    color: white;
    padding: 1em 0;
    font-size: 2em;
    margin: auto;
  }

  a.read-more {
    display: block;
    float: right;
    clear: both;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.5em 1em;
    margin-right: $gutter;
    color: white;
    margin-bottom: $gutter;
    mix-blend-mode: hard-light;

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  .not-front & {
    margin-top: 2em;
    width: 100%;
    float: left;
  }
}
.field-name-field-tags {
  float: left;
  width: 100%;
}
.view-auction-tips > .view-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
  padding: 0 ($gutter / 2) ($gutter / 2);

  .views-row {
    flex: 0 1 269px;
    background-color: $dark;
    text-align: center;
    margin: $gutter / 2;
    transition: 666ms ease all;
  }

  h3 {
    @include condensed-heading();
    margin: .5em 0;
  }

  p {
    color: white;
    padding: 0 $gutter $gutter / 2;
    line-height: 1.1;
  }
}

.label-inline {
  float: left;
}

span.file {
  display: block;
  a {
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    margin-top: -1.6em;
    margin-left: 2em;
  }
  img {
    display: none;
  }
  &:before {
    content: "";
    @include pdf-background-icon;
  }
}

.node.view-mode-full {
  margin-bottom: $base-spacing * 2;
}

.drop-down {
  @include add-drop-down;
}

.field-group-accordion, .fields-container {
  > div {
    border-top: 1px solid $light-gray;
    padding: 0 0 $gutter / 2;
  }

  h3 {
    color: $bright-red;
    margin: $gutter / 2 0;
    @include add-drop-down;
  }

  .field {
    display: none;
  }

  @include media($large-screen-up) {
    .field {
      display: block;
    }
  }
}

.post-list {

  .view-footer {
    text-align: center;

    a {
      @include condensed-heading($bright-red);
      @include add-arrow-after;

      margin: 1em 0;
      display: inline-block;
      padding: .2em;
    }
  }

  .views-row {
    border-bottom: 1px solid silver;
    margin-bottom: 2em;
    padding-bottom: 1em;
  }
  .group-head {
    float: left;
    width: 100%;

    > a { //image
      display: inline-block;
      float: left;
      margin: 0 $gutter / 2 $gutter /2 0;
      height: 100px;
      background-color: $light;
    }

    h3 {
      font-size: 1.5em;
      margin-bottom: 0;
      a {
        color: black;
      }
    }

    .group-meta {
      * {
        float: left;
      }

      b {
        margin-right: .3em;
        &:before {
          content: "by";
          margin-right: 0.3em;
          font-weight: 100;
          font-style: italic;
        }
      }
    }
  }

  #content & {
    .field-name-field-image {
      float: left;
      width: 220px;
      height: 220px;
      margin: 0 $gutter / 2 $gutter /2 0;
    }

    .field.field-name-title {
      float: left;
    }

    .field-name-body {
      width: calc(100% - 220px);
    }

    .group-meta {
      float: right;
    }

    .views-row {
      border-bottom: 1px solid silver;
      margin-bottom: 2em;
      padding-bottom: 1em;
    }
  }
}

.slick--carousel {
  position: relative;
}

body.page-node-267 {
  h1#page-title {
    text-align: center;
  }

  h4 {
    color: $medium-gray;
  }

  h5 {
    font-size: 1.4em;
    margin: 2em auto;
  }
}


.about-icons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
  margin: 2em auto;

  h4 {
    color: $medium-gray;
  }

  > div {
    flex: 0 0 250px;
    background-image: url(/sites/all/themes/aucor/images/about-page-icons.png);
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 4em;
    margin-bottom: 2em;

    &.connected {
      background-position: 100px top;
    }

    &.hammer {
      background-position: -200px top;
    }

    &.africa {
      background-position: -520px top;
    }

    &.radio {
      background-position: -825px top;
    }

    &.world {
      background-position: -1150px top;
    }

    &.loud {
      background-position: -1465px top;
    }

    &.bee {
      background-position: -1775px top;
    }

    &.global {
      background-position: -2080px top;
    }
  }
}